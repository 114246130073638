import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiService } from '../../services/api.service';
import { UserModel } from '../../models/user.model';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manage-users',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatSlideToggleModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatMenuModule
  ],
  templateUrl: './manage-users.component.html',
  styleUrl: './manage-users.component.scss',
})
export class ManageUsersComponent implements OnInit {
  protected isAdmin?: boolean;
  protected dataSource = new MatTableDataSource<UserModel>([]);
  protected displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'maxRevExtraction',
    'active',
    'passwordReset',
    'extractionsMade',
    'actions',
  ];
  protected profileForm: FormGroup;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    protected apiService: ApiService,
    private _snackBar: MatSnackBar
  ) {
    this.profileForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getUsers();
  }

  private getUsers(): void {
    this.apiService.get('users/').subscribe({
      next: (response) => {
        this.dataSource.data = response;
        this.isAdmin = response.length > 1;
        if(!this.isAdmin){
          this.profileForm.setValue({
            "name": this.dataSource.data[0].name,
            "email": this.dataSource.data[0].email,
            "phone": this.dataSource.data[0].phone
          })
        }
      },
      error: ({ error }) => {
        this._snackBar.open(error.message, 'ok', { duration: 2000 });
      },
    });
  }

  protected newUser() {
    let dataToDisplay = [...this.dataSource.data];
    dataToDisplay.unshift({
      id: '',
      name: '',
      email: '',
      active: true,
      editMode: true,
      extractionsMade: 0,
    });
    this.dataSource.data = dataToDisplay;
  }

  protected canAddNewUser(): boolean {
    return this.dataSource.data[0]?.id.length > 0;
  }

  protected save(user: UserModel) {
    user.editMode = false;

    let httpVerb: string = 'put';
    let url: string = 'users/' + user.id;

    const createNewUser: boolean = !user.id;

    if (createNewUser) {
      user.password = '123456';
      httpVerb = 'post';
      url = 'users/';
    }

    this.apiService[createNewUser ? 'post' : 'put'](url, user).subscribe({
      next: ({ message }) => {
        this._snackBar.open(message, 'ok', { duration: 2000 });
      },
      error: ({ error }) => {
        this._snackBar.open(error.message, 'ok', { duration: 2000 });
      },
    });
  }

  protected cancel(user: UserModel) {
    user.editMode = false;
    if (user.id.length === 0) {
      this.dataSource.data = this.dataSource.data.slice(1);
    }
  }

  sendRecoveryEmail(user: UserModel) {
    this.apiService.post('request_password_recovery', {
      email: user.email,
    }).subscribe({
      next: ({ message }) => {
        this._snackBar.open(message, 'ok', { duration: 2000 });
      },
      error: ({ error }) => {
        this._snackBar.open(error.message, 'ok', { duration: 2000 });
      },
    });
  }

  updateProfile(){
    // this.profileForm.setValue({
    this.dataSource.data[0].name = this.profileForm.get('name')?.value;
    this.dataSource.data[0].email = this.profileForm.get('email')?.value;
    this.dataSource.data[0].phone = this.profileForm.get('phone')?.value;

    this.save(this.dataSource.data[0])
  }


  private apiUrl = `${environment.apiUrl}users/download-extraction/`;

  downloadExtractionLog(userId: string): void {
    const url = `${this.apiUrl}${userId}`;
    
    this.http.get(url, { responseType: 'blob' }).subscribe(
      (blob) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = `extraction_report_${userId}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);  // Clean up after the download
      },
      (error) => console.error('Error downloading file', error)
    );
  }
}
