import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth-service.service';
import { TwoFactorAuthComponent } from '../../components/two-factor-auth/two-factor-auth.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    TwoFactorAuthComponent,
    RouterModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  protected hide = true;
  protected showMFA: boolean = false;

  protected get isLoaded(): boolean {
    return !this.authService.isLoggedIn;
  }

  constructor(
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    protected authService: AuthService,
    private apiService: ApiService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // setTimeout(() => {
    // this.isLoaded = true;
    // }, 2000);
  }

  sendRecoveryEmail(): void {
    const email = this.loginForm.get('username')?.value;

    if (this.apiService.isLoading) return;

    if (!email) {
      this._snackBar.open('Please, type your username', 'ok', {
        duration: 2000,
      });
      return;
    }

    this.apiService
      .post('request_password_recovery', {
        email,
      })
      .subscribe({
        next: ({ message }) => {
          this._snackBar.open(message, 'ok', { duration: 2000 });
        },
        error: ({ error }) => {
          this._snackBar.open(error.message, 'ok', { duration: 2000 });
        },
      });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      const email = this.loginForm.get('username')?.value;
      const password = this.loginForm.get('password')?.value;

      this.authService.login(email, password).subscribe({
        next: () => {
          // this.router.navigate(['calculator']);
          this.showMFA = true;
        },
        error: ({ error }) => {
          // console.log(error.message);
          this._snackBar.open(error.message, 'ok', {
            duration: 2000,
          });
        },
      });
    }
  }
}
