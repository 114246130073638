<section class="flex flex-col gap-6 h-full" [ngClass]="{'items-center justify-center': !isAdmin}">

    @if (isAdmin) {
    <div class="flex flex-row justify-between">
        <h2 class="!font-black">Manage Users</h2>

        <button mat-raised-button color="primary" (click)="newUser()" [disabled]="!canAddNewUser() || apiService.isLoading">
            <mat-icon [ngClass]="{'animate-spin':apiService.isLoading}">{{ apiService.isLoading? 'refresh': 'person_add'}}</mat-icon>
            New User
        </button>
    </div>

    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let user">
                <input class="input-field" type="text" [readonly]="!user.editMode" [(ngModel)]="user.name">
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> E-mail </th>
            <td mat-cell *matCellDef="let user">
                <input class="input-field" type="text" [readonly]="!user.editMode" [(ngModel)]="user.email">
            </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let user">
                <input class="input-field" type="text" [readonly]="!user.editMode" [(ngModel)]="user.phone">
            </td>
        </ng-container>

        <ng-container matColumnDef="extractionsMade">
            <th mat-header-cell *matHeaderCellDef class="w-40">
                Extractions Made
            </th>
            <td mat-cell *matCellDef="let user">
                <input class="input-field" type="text" [readonly]="true" [value]="user.extractionsMade">
            </td>
        </ng-container>

        <ng-container matColumnDef="maxRevExtraction">
            <th mat-header-cell *matHeaderCellDef class="w-40">
                Extraction Limit
            </th>
            <td mat-cell *matCellDef="let user">
                @if(user.maxRevExtraction || user.editMode){
                <input class="input-field" type="text" [readonly]="!user.editMode" [(ngModel)]="user.maxRevExtraction">
                }
                @else {
                <input class="input-field" type="text" [readonly]="true" value="unlimited">
                }
            </td>
        </ng-container>


        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let user">
                <mat-slide-toggle [color]="'primary'" [disabled]="!user.editMode" [checked]="true"
                    [(ngModel)]="user.active"></mat-slide-toggle>
            </td>
        </ng-container>


        <ng-container matColumnDef="passwordReset">
            <th mat-header-cell *matHeaderCellDef> Password </th>
            <td mat-cell *matCellDef="let user">

                @if (user.editMode) {
                <button mat-raised-button color="warn" class="w-full" (click)="sendRecoveryEmail(user)">
                    <mat-icon>email</mat-icon>
                    Reset Password
                </button>
                }
                @else {
                <input class="input-field" type="password" [readonly]="true" value="0123456789">

                }

            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let user">
                <div class="flex flex-row justify-end">
                    @if (user.editMode) {
                    <button mat-icon-button color="primary" (click)="save(user);">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="cancel(user)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    }
                    @else {

                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon color="white">more_vert</mat-icon>
                    </button>
                
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item dir="rtl" (click)="user.editMode = true">
                            Edit <mat-icon class="ml-2">edit</mat-icon>
                        </button>
                        <button mat-menu-item dir="rtl" (click)="downloadExtractionLog(user.id)">
                            Log <mat-icon class="ml-2">file_download</mat-icon>
                        </button>
                    </mat-menu>

                    }
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div [hidden]="dataSource.data.length == 0">
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic users">
        </mat-paginator>
    </div>
    }
    @else if (isAdmin === false) {
    <mat-card>
        <mat-card-content>
            <mat-card-title>Edit Profile</mat-card-title>

            <form [formGroup]="profileForm" class="flex flex-col mt-3 mb-4" (ngSubmit)="updateProfile()">

                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" formControlName="name">
                </mat-form-field>

                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>E-mail</mat-label>
                    <input matInput type="text" formControlName="email">
                </mat-form-field>

                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Phone</mat-label>
                    <input matInput type="text" formControlName="phone">
                </mat-form-field>

                <button mat-raised-button color="warn" class="w-full mb-4"
                    (click)="sendRecoveryEmail(dataSource.data[0])">
                    <mat-icon>email</mat-icon>
                    Recover password
                </button>

                <button mat-raised-button class="w-full" color="primary" type="submit"
                    [disabled]="!profileForm.dirty || profileForm.invalid || apiService.isLoading">
                    <mat-icon [ngClass]="{'animate-spin':apiService.isLoading}">
                        {{ apiService.isLoading? 'refresh': 'save'}}
                    </mat-icon>
                    Save Changes
                </button>

            </form>

            <mat-divider></mat-divider>

            <p class="opacity-70 mt-3">
                <mat-icon inline="true" class="align-middle">info</mat-icon>
                You have made <strong>{{dataSource.data[0].extractionsMade}}</strong> extractions so far!
                <br>
                <mat-icon inline="true" class="align-middle">info</mat-icon>
                &nbsp;<strong>{{ dataSource.data[0].maxRevExtraction ?? 'Unlimited' }}</strong> extractions remaining.
            </p>

        </mat-card-content>
    </mat-card>
    }
    @else {
    <mat-icon class="animate-spin opacity-70" style="scale: 2">refresh</mat-icon>

    }


</section>