import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription, interval, take } from 'rxjs';

@Component({
  selector: 'app-countdown',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './countdown.component.html',
  styleUrl: './countdown.component.scss',
})
export class CountdownComponent {
  @Input() seconds: number = 0;
  protected currentCount: number = 0;
  private countdownSubscription?: Subscription;

  ngOnDestroy(): void {
    this.stopCountdown();
  }

  startCountdown(): void {
    if (!this.countdownSubscription) {
      this.currentCount = this.seconds;
      this.countdownSubscription = interval(1000)
        .pipe(take(this.seconds))
        .subscribe(() => {
          this.currentCount--;
        });
    }
  }

  stopCountdown(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
      this.countdownSubscription = undefined;
      this.currentCount = 0;
    }
  }

  updateCountDown(seconds: number): void {
    if (this.currentCount == 0 || seconds < this.currentCount) {
      this.seconds = seconds;
      this.stopCountdown();
      this.startCountdown();
    }
  }

  protected formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(
        remainingSeconds
      )}`;
    } else {
      return `${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
    }
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
