import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth-service.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.password1 === control.value.password2
    ? null
    : { PasswordNoMatch: true };
};

@Component({
  selector: 'app-password-recovery',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButton,
    MatInputModule,
    FormsModule,
  ],
  templateUrl: './password-recovery.component.html',
  styleUrl: './password-recovery.component.scss',
})
export class PasswordRecoveryComponent {
  passwordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {
    this.passwordForm = this.formBuilder.group(
      {
        password1: ['', [Validators.required, this.validatePassword]],
        password2: ['', [Validators.required]],
      },
      { validators: confirmPasswordValidator }
    );
  }

  validatePassword(control: FormControl) {
    const password: string = control.value;

    let errors: any = {};

    if (password.length < 20) errors['minlength'] = true;

    if (!/[a-z]/.test(password)) errors['lowercase'] = true;
    if (!/[A-Z]/.test(password)) errors['uppercase'] = true;
    if (!/\d/.test(password)) errors['number'] = true;
    if (!/[!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?]/.test(password))
      errors['special'] = true;

    return Object.keys(errors).length ? errors : null;
  }

  onSubmit() {
    const token = this.route.snapshot.paramMap.get('token');
    this.apiService.post('recover_password', {
      token,
      newPassword: this.passwordForm.get('password1')?.value,
    }).subscribe({
      next: ({ message }) => {
        this._snackBar.open(message, 'ok', { duration: 2000 });

        setTimeout(() => {
          this.router.navigate(['']);
        }, 5000);
      },
      error: ({ error }) => {
        this._snackBar.open(error.message, 'ok', { duration: 2000 });
      },
    });;
  }
}
