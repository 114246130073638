<mat-dialog-content>
    <h2 mat-dialog-title class="text-center">It looks like you've opened another tab.</h2>

    <p class="text-center">Would you like to continue here instead?</p>

</mat-dialog-content>

<mat-dialog-actions class="w-full !justify-evenly">
    <button mat-button mat-dialog-close>No</button>
    <button mat-button (click)="closeDialog(true)">Yes</button>
</mat-dialog-actions>