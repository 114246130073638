import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatButtonModule, MatIconModule],
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
})
export class FileUploaderComponent {

  @Output() change = new EventEmitter<Event>();
  @Input() accept: string = "";
  @Input() label: string = "Upload File";
  @Input() color: string = "";
  @Input() disabled: boolean = false;
  
  fileName: string = '';

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.fileName = input.files[0].name;
    }
    this.change.emit(event);
  }
}
