import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth-service.service';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ClipboardService } from '../../services/clipboard.service';

@Component({
  selector: 'app-two-factor-auth',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
  ],
  templateUrl: './two-factor-auth.component.html',
  styleUrl: './two-factor-auth.component.scss',
})
export class TwoFactorAuthComponent implements OnInit {
  qrCode: string | null = null;
  tokenForm: FormGroup;
  securityKey: string = '';
  @Input() email: string = '';
  @Input() password: string = '';

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
    private clipboardService: ClipboardService
  ) {
    this.tokenForm = this.fb.group({
      token: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // this.authService.generateSecret().subscribe(() => {
    this.authService.generateQRCode(this.email, this.password).subscribe((data: any) => {
      this.qrCode = data.qr_code;
      this.securityKey = data.security_key;
    });
    // });
  }

  verifyToken(): void {
    const token = this.tokenForm.get('token')?.value;
    this.authService.verifyToken(this.email, this.password, token).subscribe({
      next: (data: any) => {
        if (data.verified) {
          this.router.navigate(['calculator']);
        } else {
          this._snackBar.open("Ivalid token", "ok", {
            duration: 2000
          });
        }
      },
      error: ({ error }) =>{
        const message = error.message ?? "Invalid token";
        this._snackBar.open(message, "ok", {
          duration: 2000
        });
      }
    });
  }

  copySecurityKey(): void {
    this.clipboardService.copyToClipboard(this.securityKey);
    this._snackBar.open("Text copied to clipboard!", "Ok", {
      duration: 2000
    })
  }
}
