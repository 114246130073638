<mat-dialog-content>
    <h2 mat-dialog-title class="text-center">Calculator Settings</h2>

    <p>The revenue calculator determines the profit using the following formula:</p>
    <br>
    <p class="text-center">
        <span class="italic bg-slate-100">
            revenue / 12 - (utilities + rent)
        </span>    
    </p>
    
    <br>
    <p class="mb-2">You can adjust it:</p>
    
    <mat-form-field class="w-full">
        <mat-label>Utilities</mat-label>
        <input matInput currencyMask class="!text-left" [(ngModel)]="utilities">
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions class="w-full !justify-evenly">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="closeDialog()">Save</button>
</mat-dialog-actions>