<section>
    <mat-card>
        <mat-card-content>
            <mat-card-title>Sign up</mat-card-title>
            <br>
            <form *ngIf="!showSuccessMessage" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="fill" class="!mb-0">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <mat-form-field appearance="fill" class="!mb-0">
                    <mat-label>E-mail</mat-label>
                    <input matInput formControlName="email" required>
                </mat-form-field>

                <mat-form-field appearance="fill" class="!mb-0">
                    <mat-label>Phone</mat-label>
                    <input matInput type="phone" formControlName="phone" required>
                </mat-form-field>

                <button mat-raised-button class="w-full" color="primary" type="submit"
                    [disabled]="!signupForm.valid || apiService.isLoading">
                    <mat-icon class="animate-spin" *ngIf="apiService.isLoading">
                        refresh
                    </mat-icon>

                    Sign up
                </button>
            </form>
            <p *ngIf="showSuccessMessage">
                Thank you for signing up! 🥳 <br><br> We will review your submission shortly and get in touch with you soon.
            </p>
        </mat-card-content>
    </mat-card>
</section>