<section class="flex justify-center items-center">
    <mat-card>
        <mat-card-content>
            <mat-card-title>Set up a new password</mat-card-title>
            <br>
            <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">

                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password1">
                </mat-form-field>

                <div class="error-list">
                    <p [ngClass]="passwordForm.controls['password1'].errors?.['required']?'text-red':'text-green'">
                        <mat-icon inline="true">check</mat-icon>
                        <mat-icon inline="true">close</mat-icon>
                        Password cannot be empty.
                    </p>
                    <p [ngClass]="passwordForm.controls['password1'].errors?.['lowercase']?'text-red':'text-green'">
                        <mat-icon inline="true">check</mat-icon>
                        <mat-icon inline="true">close</mat-icon>
                        It must contain at least one lowercase letter.
                    </p>
                    <p [ngClass]="passwordForm.controls['password1'].errors?.['uppercase']?'text-red':'text-green'">
                        <mat-icon inline="true">check</mat-icon>
                        <mat-icon inline="true">close</mat-icon>
                        It must contain at least one uppercase letter.
                    </p>
                    <p [ngClass]="passwordForm.controls['password1'].errors?.['number']?'text-red':'text-green'">
                        <mat-icon inline="true">check</mat-icon>
                        <mat-icon inline="true">close</mat-icon>
                        It must contain at least one number.
                    </p>
                    <p [ngClass]="passwordForm.controls['password1'].errors?.['special']?'text-red':'text-green'">
                        <mat-icon inline="true">check</mat-icon>
                        <mat-icon inline="true">close</mat-icon>
                        It must contain at least one special character.
                    </p>
                    <p [ngClass]="passwordForm.controls['password1'].errors?.['minlength']?'text-red':'text-green'">
                        <mat-icon inline="true">check</mat-icon>
                        <mat-icon inline="true">close</mat-icon>
                        It must be at least 20 characters long.
                    </p>
                </div>

                <br>

                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Repeat the password</mat-label>
                    <input matInput type="password" formControlName="password2">
                </mat-form-field>

                <div class="error-list">
                    <p [ngClass]="!passwordForm.controls['password2'].errors?.['required'] && !passwordForm.errors?.['PasswordNoMatch']?'text-green':'text-red'">
                        <mat-icon inline="true">check</mat-icon>
                        <mat-icon inline="true">close</mat-icon>
                        Matchings passwords
                    </p>
                </div>

                <br>

                <button mat-raised-button class="w-full" color="primary" type="submit" [disabled]="!passwordForm.valid">
                    Confirm Password
                </button>
    
            </form>


        </mat-card-content>
    </mat-card>
</section>