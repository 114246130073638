import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EPlatform } from '../../enums/platforms.enum';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-external-login',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './external-login.component.html',
  styleUrl: './external-login.component.scss',
})
export class ExternalLoginComponent {
  readonly dialogRef = inject(MatDialogRef<ExternalLoginComponent>);
  protected loginForm: FormGroup;

  constructor(
    private _snackBar: MatSnackBar,
    protected apiService: ApiService,
    private fb: FormBuilder
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.apiService
      .post('login_to_airdna', {
        username: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value,
      })
      .subscribe({
        next: (response) => {
          if ('token' in response) this.saveCookie(response.token);
          else {
            this._snackBar.open('Something went wrong :(', 'ok', {
              duration: 2000,
            });
          }
        },
        error: ({ error }) => {
          this._snackBar.open(error.message, 'ok', {
            duration: 2000,
          });
        },
      });
  }

  saveCookie(cookie: string): void {
    this.apiService
      .post('user_cookie', {
        platform_id: EPlatform.AIR_DNA,
        cookie,
      })
      .subscribe({
        next: (resp) => {
          this.dialogRef.close();
          this._snackBar.open(resp.message, 'ok', {
            duration: 2000,
          });
        },
        error: ({ error }) => {
          this._snackBar.open(error.message, 'ok', {
            duration: 2000,
          });
        },
      });
  }
}
