
@if (isLoaded == false){
  <!-- Loading Icon -->
  <section class="min-h-full flex items-center w-full justify-center">
      <mat-icon class="animate-spin text-gray-400" style="scale: 2">refresh</mat-icon>
  </section>
}
@else{
  <!-- Login Form -->
  <section>
    <mat-card>
      <mat-card-content>

        <app-two-factor-auth *ngIf="showMFA" [email]="this.loginForm.get('username')?.value" 
        [password]="this.loginForm.get('password')?.value" ></app-two-factor-auth>

        <form *ngIf="!showMFA" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <mat-card-title>Login</mat-card-title>
          <br>
          <mat-form-field appearance="fill" class="!mb-0">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" required>
          </mat-form-field>

          <mat-form-field appearance="fill" class="!mb-0">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" required>
            <!-- <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required> -->
            <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button> -->
          </mat-form-field>

          <button mat-raised-button class="w-full" color="primary" type="submit"
            [disabled]="!loginForm.valid">Login</button>

          <p class="text-sm text-center mt-5 cursor-pointer text-blue-700" (click)="sendRecoveryEmail()">
            Forgot my password
          </p>
          <p class="text-sm text-center mt-2">Don't have an account? 
            <span class="text-blue-700 cursor-pointer" routerLink="signup">Sign up</span>
          </p>

        </form>
      </mat-card-content>
    </mat-card>
  </section>
}