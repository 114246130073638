import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public isLoading: boolean = false;

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  public get(resource: string, params?: any): Observable<any> {
    this.isLoading = true;

    let httpParams = new HttpParams();
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }

    let headers = new HttpHeaders().set('Authorization', this.cookieService.get('token'));
    return this.http.get<any>(environment.apiUrl + resource, { headers, params: httpParams })
      .pipe(
        finalize(() => this.isLoading = false)
      );
  }

  public post(resource: string, body: any): Observable<any> {
    this.isLoading = true;

    return this.http.post<any>(environment.apiUrl + resource, body, {
      headers: { Authorization: this.cookieService.get('token') },
    }).pipe(
      finalize(() => this.isLoading = false)
    );
  }

  public put(resource: string, body: any): Observable<any> {
    this.isLoading = true;

    return this.http.put<any>(environment.apiUrl + resource, body, {
      headers: { Authorization: this.cookieService.get('token') },
    }).pipe(
      finalize(() => this.isLoading = false)
    );
  }
}
