<mat-dialog-content>
    <div class="flex flex-col items-center">
        <img src="../../../assets/airdna-logo.png" alt="Air Dna Logo" width="145px">
        <h2 mat-dialog-title>Please, log in to continue</h2>

    </div>

    <form [formGroup]="loginForm" class="flex flex-col items-stretch" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" required cdkFocusInitial>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password" required>
        </mat-form-field>
        <button mat-raised-button class="w-full"  mat-dialog-close>Cancel</button>
        <button mat-raised-button class="w-full my-4" color="primary" type="submit" [disabled]="loginForm.invalid || apiService.isLoading">
            @if(apiService.isLoading){
                <mat-icon class="animate-spin">refresh</mat-icon>
            }
            @else {
                Log In
            }
        </button>
        <p class="text-xs opacity-80 text-center">
            We DOT NOT store your email and password.
        </p>
    </form>

</mat-dialog-content>