import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private __isLoggedIn: boolean= false;
  public get isLoggedIn():boolean{
    return this.__isLoggedIn;
  }

  constructor (
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  generateQRCode(email: string, password: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/generate_qr`, {
      email,
      password,
    });
  }

  verifyToken(email: string, password: string, token: string): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/verify_token`, {
        email,
        password,
        token,
      })
      .pipe(
        tap((response) => {
          if (response.verified == true)
            this.cookieService.set('token', response.token);
        })
      );
  }

  // Login method
  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}login`, {
      email,
      password,
    });
  }

  // Logout method
  logout(): void {
    this.cookieService.delete('token'); // Remove the token cookie
    this.__isLoggedIn = false;
  }

  // Method to check if the user is logged in
  checkLoginStatus(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const token = this.cookieService.get('token');
      if (token) {
        this.http
          .get<any>(`${environment.apiUrl}check_login`, {
            headers: { Authorization: token },
          })
          .subscribe({
            next: (response) => {
              if (response && response.message === 'User is logged in') {
                resolve(true); // Resolve the promise with true
              } else {
                resolve(false); // Resolve the promise with false
              }
            },
            error: (error) => {
              resolve(false); // Resolve the promise with false
            },
          });
      } else {
        resolve(false); // Resolve the promise with false
      }
    }).then((isLoggedIn: boolean) => {
      this.__isLoggedIn = isLoggedIn;
      return isLoggedIn;
    });
  }
}
