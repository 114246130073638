import { Component, inject } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

@Component({
  selector: 'app-browser-tab-conflict',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogActions,
  ],
  templateUrl: './browser-tab-conflict.component.html',
  styleUrl: './browser-tab-conflict.component.scss'
})
export class BrowserTabConflictComponent {
  readonly dialogRef = inject(MatDialogRef<BrowserTabConflictComponent>);

  closeDialog(result:boolean): void {
    this.dialogRef.close(result);
  }
}
