import * as XLSX from 'xlsx';
import { Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ExcelService } from '../../services/excel.service';
import { CommonModule, CurrencyPipe, NgClass } from '@angular/common';
import { comp, PropertyModel } from '../../models/property.model';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileUploaderComponent } from '../../components/file-uploader/file-uploader.component';
import { CountdownComponent } from '../../components/countdown/countdown.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { HttpStatusCode } from 'axios';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ExternalLoginComponent } from '../../components/external-login/external-login.component';
import { FormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CalculatorSettingsComponent } from '../../components/calculator-settings/calculator-settings.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserTabConflictComponent } from '../../components/browser-tab-conflict/browser-tab-conflict.component';
import { checkNumberFilter, NumberFilterComponent, NumberFilterOutput } from '../../components/number-filter/number-filter.component';

type FilterValues = {
  location?: string;
  profit?: NumberFilterOutput;
  revenue?: NumberFilterOutput;
  price?: NumberFilterOutput;
  average_revenue_with_pool?: NumberFilterOutput;
  average_revenue_with_no_pool?: NumberFilterOutput;
}

@Component({
  selector: 'app-calculator',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    FileUploaderComponent,
    MatButtonModule,
    MatIconModule,
    CountdownComponent,
    MatPaginatorModule,
    MatDialogModule,
    CurrencyMaskModule,
    MatSortModule,
    MatInputModule,
    NgClass,
    NumberFilterComponent,
  ],
  templateUrl: './calculator.component.html',
  styleUrl: './calculator.component.scss',
  providers: [CurrencyPipe],
})
export class CalculatorComponent implements OnDestroy {
  tableData: any[][] = [];
  continueFetching: boolean = true;
  displayedColumns: string[] = [
    'position',
    'location',
    'link',
    'price',
    'ba',
    'br',
    'airdnaRevenue',
    'profit',
    'comps_count',
    'comps_with_pool',
    'comps_revenue_with_pool',
    'average_revenue_with_pool',
    'comps_revenue_with_no_pool',
    'average_revenue_with_no_pool',
  ];
  loading: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(CountdownComponent) countdown?: CountdownComponent;
  @ViewChild(MatSort) sort!: MatSort;

  readonly dialog = inject(MatDialog);
  protected utilities: number = 300;
  protected fileName: string = 'Sample.xlsx';
  protected extractionKey?: string;
  protected showUploadErrorMsg: boolean = false;
  private readonly columnsToExport: (keyof PropertyModel)[] = [
    'location',
    'price',
    'ba',
    'sq. ft.',
    'br',
    'link',
    'profit',
    'airdnaRevenue',
    'comps_count',
    'comps_with_pool',
    'comps_revenue_with_pool',
    'average_revenue_with_pool',
    'comps_revenue_with_no_pool',
    'average_revenue_with_no_pool',
  ];

  constructor(
    private apiService: ApiService,
    private excelService: ExcelService,
    private currencyPipe: CurrencyPipe,
    private _liveAnnouncer: LiveAnnouncer,
    private _snackBar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.stopFetchingEstimate();
  }

  sampleData: PropertyModel[] = [
    {
      location: '2831 Mayfair Ave, Henderson, NV 89074',
      price: 2300,
      ba: 2,
      br: 3,
    },
    {
      location: '1223 El Fuego Trl, Henderson, NV 89074',
      price: 2300,
      ba: 2.5,
      br: 4,
    },
    {
      location: '522 Sacred Lotus, Henderson, NV 89011',
      price: 2300,
      ba: 2,
      br: 2,
    },
    {
      location: '75 N Valle Verde Dr APT 1824, Henderson, NV 89074',
      price: 2300,
      ba: 2,
      br: 2,
    },
    {
      location: '401 East Merlayne Dr, Henderson, NV 89011',
      price: 2300,
      ba: 1,
      br: 2,
    },
  ];

  dataSource = new MatTableDataSource<PropertyModel>([]);

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  truncateString(input: string, n: number): string {
    if (input.length > n) {
      return input.slice(0, n) + '...';
    }
    return input;
  }

  searchTerm: string = '';

  applyFilter() {

    this.filterValues.location = this.searchTerm.trim().toLowerCase();

    this.dataSource.filter = JSON.stringify(this.filterValues);

  }

  toPercentage(decimal?: number): string {
    if (!decimal) return '--';
    return `${(decimal * 100).toFixed(0)}%`;
  }

  generateExtractionKey(): Promise<void> {
    const array = new Uint8Array(20);
    // Fill the array with cryptographically secure random values
    crypto.getRandomValues(array);
    // Convert the array to a hexadecimal string
    const extraction_key = Array.from(array)
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');

    return new Promise((resolve, reject) => {
      this.apiService
        .post('scraper/user_extraction_key', {
          extraction_key,
        })
        .subscribe({
          next: () => {
            this.extractionKey = extraction_key;
            resolve();
          },
          error: ({ error }) => {
            this._snackBar.open(error.message, 'ok', { duration: 2000 });
            reject();
          },
        });
    });
  }

  onFileChange(event: any): void {
    const target: DataTransfer = <DataTransfer>event.target;

    this.fileName = target.files[0].name;

    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = <any[][]>XLSX.utils.sheet_to_json(ws, { header: 1 });

      /* extract headers and rows */
      const headers = data[0].map((colName) => {
        colName = colName.toLowerCase();

        switch (colName) {
          case 'address':
            colName = 'location';
            break;

          case 'listing url':
            colName = 'link';
            break;

          case 'beds':
            colName = 'br';
            break;

          case 'baths':
            colName = 'ba';
            break;

          case 'footage':
            colName = 'sq. ft.';
            break;
        }

        return colName;
      });

      const mandatoryColumns: (keyof PropertyModel)[] = [
        'location',
        'ba',
        'br',
      ];

      for (const column of mandatoryColumns) {
        if (!headers.includes(column)) {
          this.dataSource.data = [];
          this.showUploadErrorMsg = true;
          return;
        }
      }

      this.dataSource.data = data
        .slice(1)
        .map((row) => {
          let item: any = {};

          for (const column of this.columnsToExport.concat(headers))
            item[column] = row[headers.indexOf(column)];

          return item;
        })
        .filter((item) => item.location)
        .map((item) => {
          if (typeof item.price == 'string' && item.price.indexOf('+') > 0) {
            [item.price, item.br] = item.price.split('+');
            item.ba = 1;
          }

          item.price = this.extractNumber(item.price);
          item.ba = this.extractNumber(item.ba);
          item.br = this.extractNumber(item.br);

          return item;
        });

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();
      this.generateExtractionKey();
    };

    reader.readAsBinaryString(target.files[0]);
  }

  filterValues: FilterValues = {}

  createFilter(): (data: PropertyModel, filter: string) => boolean {
    let filterFunction = function (
      data: PropertyModel,
      filter: string
    ): boolean {

      let searchTerms: FilterValues = JSON.parse(filter);

      const locationFilter = !searchTerms.location || data.location.toString().toLowerCase().indexOf(searchTerms.location) !== -1;

      const profitFilter = !searchTerms.profit || checkNumberFilter(data.profit??0, searchTerms.profit.filterType , searchTerms.profit.value);
      
      const revenueFilter = !searchTerms.revenue || checkNumberFilter(typeof data.airdnaRevenue == 'number' ? data.airdnaRevenue : 0, searchTerms.revenue.filterType , searchTerms.revenue.value);

      const priceFilter = !searchTerms.price || checkNumberFilter(data.price??0, searchTerms.price.filterType , searchTerms.price.value);

      const avgRevenueWithPoolFilter = !searchTerms.average_revenue_with_pool || checkNumberFilter(data.average_revenue_with_pool??0, searchTerms.average_revenue_with_pool.filterType , searchTerms.average_revenue_with_pool.value);

      const avgRevenueWithNoPoolFilter = !searchTerms.average_revenue_with_no_pool || checkNumberFilter(data.average_revenue_with_no_pool??0, searchTerms.average_revenue_with_no_pool.filterType , searchTerms.average_revenue_with_no_pool.value);
      
      return locationFilter 
        && profitFilter
        && revenueFilter
        && priceFilter
        && avgRevenueWithPoolFilter
        && avgRevenueWithNoPoolFilter;
    };
    return filterFunction;
  }

  getFilteredIndexes(headers: string[], desiredColumns: string[]): number[] {
    return headers
      .map((header, index) =>
        desiredColumns.includes(header.toLowerCase()) ? index : -1
      )
      .filter((index) => index !== -1);
  }

  async startFetchingEstimate(currentPropertyIndex?: number): Promise<void> {
    this.dataSource.filter = '';
    if (
      !currentPropertyIndex ||
      typeof this.dataSource.data[currentPropertyIndex]?.airdnaRevenue ==
        'number'
    ) {
      this.continueFetching = true;
      let sum = 1;
      if (!currentPropertyIndex) {
        currentPropertyIndex = 0;
        sum = 0;
      }

      if (currentPropertyIndex < this.dataSource.data.length) {
        currentPropertyIndex +=
          sum +
          this.dataSource.data
            .slice(currentPropertyIndex + sum)
            .findIndex((item) => typeof item.airdnaRevenue != 'number');

        this.goToRow(currentPropertyIndex);
      }
    }

    const property = this.dataSource.data[currentPropertyIndex];
    if (property == undefined || !this.continueFetching) {
      this.stopFetchingEstimate();
      return;
    }

    if (this.countdown && this.countdown.seconds == 0)
      this.countdown.updateCountDown(
        this.dataSource.data.filter((item) => item.airdnaRevenue == undefined)
          .length * 10
      );

    this.loading = true;
    property.loading = true;

    // Paginate
    const nextPageFirstIndex =
      this.paginator.pageSize * (this.paginator.pageIndex + 1);
    if (currentPropertyIndex == nextPageFirstIndex) this.paginator.nextPage();

    // Sleep
    // const sleepDuration = this.getRandomSleepDuration(5, 10);
    // await this.sleep(sleepDuration);
    if (!this.continueFetching) {
      // this.stopFetchingEstimate();
      property.loading = false;
      return;
    }

    // let revenue: string | number = 0;
    const {
      revenue,
      comps_count,
      comps_with_pool,
      comps_revenue_with_pool,
      comps_revenue_with_no_pool,
    } = await this.apiService
      .post('scraper/fetch_revenue_estimate', {
        address: property.location,
        bedrooms: property.br,
        bathrooms: property.ba,
        link: property.link,
        file_name: this.fileName,
        extraction_key: this.extractionKey,
      })
      .toPromise()
      .then((response) => {
        this.startFetchingEstimate(currentPropertyIndex + 1);

        return response;
      })
      .catch((response) => {
        const error = response.error;

        if (response.status == HttpStatusCode.InternalServerError)
          this.startFetchingEstimate(currentPropertyIndex + 1);
        else this.stopFetchingEstimate();

        if (response.status == HttpStatusCode.Unauthorized)
          this.dialog.open(ExternalLoginComponent, {
            enterAnimationDuration: 200,
            exitAnimationDuration: 200,
          });
        else if (response.status == HttpStatusCode.NotAcceptable) {
          this.dialog
            .open(BrowserTabConflictComponent, {
              enterAnimationDuration: 200,
              exitAnimationDuration: 200,
            })
            .afterClosed()
            .subscribe((result) => {
              if (result === true) {
                this.generateExtractionKey().then(() => {
                  this.startFetchingEstimate();
                });
              }
            });

          return {
            revenue: "Can't proceed",
            comps_count: 0,
            comps_with_pool: 0,
            comps_revenue_with_pool: [],
            comps_revenue_with_no_pool: [],
          };
        }

        return {
          revenue: error.message,
          comps_count: 0,
          comps_with_pool: 0,
          comps_revenue_with_pool: [],
          comps_revenue_with_no_pool: [],
        };
      });

    property.loading = false;
    property.airdnaRevenue = revenue;
    property.comps_count = comps_count;
    property.comps_with_pool = comps_with_pool;
    property.comps_revenue_with_pool = comps_revenue_with_pool;
    property.comps_revenue_with_no_pool = comps_revenue_with_no_pool;
    property.average_revenue_with_pool = this.calculateAverageRevenue(
      comps_revenue_with_pool
    );
    property.average_revenue_with_no_pool = this.calculateAverageRevenue(
      comps_revenue_with_no_pool
    );

    if (this.countdown && this.continueFetching)
      this.countdown.updateCountDown(
        this.dataSource.data.filter((item) => item.airdnaRevenue == undefined)
          .length * 10
      );

    //Calculates the profit
    property.profit = this.calculateProfit(property);
  }

  stopFetchingEstimate(): void {
    if (this.countdown) this.countdown.stopCountdown();
    this.continueFetching = false;
    this.loading = false;
  }

  sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getRandomSleepDuration(minSeconds: number, maxSeconds: number): number {
    return Math.random() * (maxSeconds - minSeconds) * 1000 + minSeconds * 1000;
  }

  extractNumber(inputString: any): number {
    if (typeof inputString == 'number') return inputString;
    if (typeof inputString != 'string') return 1;

    inputString = inputString.replaceAll(',', '');

    const regex = /\d+\.?\d*/g;
    const matches = inputString.match(regex);

    if (matches && matches.length > 0) {
      return parseFloat(matches[0]);
    } else {
      return 1;
    }
  }

  formatAirdnaRevenue(value: any): string {
    if (!value) {
      return '--';
    }

    if (typeof value == 'number') {
      let revenue = this.currencyPipe.transform(value, 'USD');
      if (revenue) return revenue;
    }

    return value;
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  formatNumberWithCommas(num: number): string {
    return num.toLocaleString('en-US');
  }

  formatCompsForExcel(comps?: comp[]): string {
    if (!comps) return '';
    return comps
      .map(
        (comp, i) =>
          `${this.formatNumberWithCommas(comp.revenue)} Comp ${i + 1}`
      )
      .join('\n');
  }

  calculateAverageRevenue(comps: comp[]): number {
    if (comps.length === 0) return 0; // Handle empty array case

    const totalRevenue = comps.reduce((sum, comp) => sum + comp.revenue, 0);
    return totalRevenue / comps.length;
  }

  exportData(): void {
    // console.table(this.dataSource.data);

    const filteredData = this.dataSource.filteredData.map((item: any) => {
      const filteredItem: Partial<
        Record<keyof PropertyModel, string | number | boolean | undefined>
      > = {};
      this.columnsToExport.forEach((key) => {
        if (
          key == 'comps_revenue_with_no_pool' ||
          key == 'comps_revenue_with_pool'
        ) {
          filteredItem[key] = this.formatCompsForExcel(item[key]);
        } else if (
          (key == 'ba' || key == 'br') &&
          typeof item[key] === 'number'
        ) {
          let suffix = '';

          if (key === 'ba') suffix = item[key] <= 1 ? ' ba' : ' bas';
          else if (key === 'br') suffix = item[key] <= 1 ? ' bd' : ' bds';

          filteredItem[key] = item[key].toFixed(item[key] % 1 === 0 ? 0 : 1);
          filteredItem[key] += suffix;
        } else filteredItem[key] = item[key];
      });
      return {
        ...item,
        ...filteredItem,
      };
    });
    this.excelService.exportAsExcelFile(filteredData, 'PropertyData');
  }

  downloadSample(): void {
    this.excelService.exportAsExcelFile(this.sampleData, 'PropertyData');
  }

  goToRow(rowIndex: number): void {
    const pageSize = this.paginator.pageSize;
    const pageIndex = Math.floor(rowIndex / pageSize);
    this.paginator.pageIndex = pageIndex;
    this.paginator._changePageSize(pageSize); // Trigger the pagination change
    this.sort.sort({ id: '', start: 'asc', disableClear: false });
  }

  shouldHighlight(row: PropertyModel): boolean {
    // Replace this condition with your actual logic
    return row.loading == true; // Example: highlight even rows
  }

  openCalculatorSettings(): void {
    this.dialog
      .open(CalculatorSettingsComponent, {
        width: '350px',
        enterAnimationDuration: 200,
        exitAnimationDuration: 200,
        data: {
          utilities: this.utilities,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'number') return;

        this.utilities = result;

        this.dataSource.data.map((property: PropertyModel) => {
          property.profit = this.calculateProfit(property);
        });
      });
  }

  calculateProfit(property: PropertyModel): number | undefined {
    if (
      typeof property.airdnaRevenue == 'number' &&
      typeof property.price == 'number'
    )
      return (property.profit =
        property.airdnaRevenue / 12 - this.utilities - property.price);
    return undefined;
  }
}
