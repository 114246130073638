import { ChangeDetectionStrategy, Component, Inject, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { CurrencyMaskModule } from 'ng2-currency-mask';

@Component({
  selector: 'app-calculator-settings',
  standalone: true,
  imports: [
    MatDialogContent,
    MatInputModule,
    MatDialogClose,
    MatDialogTitle,
    MatDialogActions,
    MatButtonModule,
    CurrencyMaskModule,
    FormsModule,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calculator-settings.component.html',
  styleUrl: './calculator-settings.component.scss',
})
export class CalculatorSettingsComponent implements OnInit {
  protected utilities: number = 0;
  readonly dialogRef = inject(MatDialogRef<CalculatorSettingsComponent>);
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.utilities = this.data.utilities;
  }

  closeDialog(): void {
    this.dialogRef.close(this.utilities);
  }
}
