<!-- src/app/components/2fa/2fa.component.html -->
<div class="w-full">

  <div class="flex flex-row items-center gap-2 justify-center">
    <div>
      <img src="../../../assets/google-authenticator.png" alt="Google Authenticator" class="w-6 aspect-square">
    </div>
    <mat-card-title>Google Authenticator</mat-card-title>
  </div>
  <br>

  <div *ngIf="qrCode" class="flex flex-col justify-center w-full items-center">
    <p class="text-center">
      Scan the code below or 
      <a class="text-blue-700 underline cursor-pointer" (click)="copySecurityKey()">
        click here to copy the key
      </a>
      and then manually add it to the Authenticator App.
    </p>
    <img [src]="'data:image/png;base64,' + qrCode" alt="QR Code" class="w-3/4">
  </div>
  <form [formGroup]="tokenForm" (ngSubmit)="verifyToken()">
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Authentication token</mat-label>
      <input id="token" formControlName="token" matInput type="text">
    </mat-form-field>


    <button mat-raised-button class="w-full" color="primary" type="submit" [disabled]="!tokenForm.valid">Verify</button>
  </form>
</div>