import { Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { CalculatorComponent } from './views/calculator/calculator.component';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from './services/auth-service.service';
import { ManageUsersComponent } from './views/manage-users/manage-users.component';
import { PasswordRecoveryComponent } from './views/password-recovery/password-recovery.component';
import { SignupComponent } from './components/signup/signup.component';

const AuthGuard = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const isLoggedIn = await authService.checkLoginStatus();

  if (isLoggedIn) return true;

  router.navigate(['./']);
  return false;
};

const NonAuthGuard = async () => {
    const authService = inject(AuthService);
    const router = inject(Router);
  
    const isLoggedIn = await authService.checkLoginStatus();
  
    if (!isLoggedIn) return true;
  
    router.navigate(['./calculator']);
    return false;
  };

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'calculator',
    component: CalculatorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage_user',
    component: ManageUsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'password_recovery/:token',
    component: PasswordRecoveryComponent,
    canActivate: [NonAuthGuard]
  }
];
